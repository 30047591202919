/* define variables default to dark theme */
:root [data-theme="dark"] {
  --background-color: #03001f;
  --sidebar-color: #010009;
  --font-color: white;
  --border-color: 1px solid #646464;
  --chat-window-color: #1c1e3a;
  --napa-blue: white;
  --semantic-action-color-foreground-neutral-white: transparent;
  --napa-blue-shadow: #fff2;
  --table-border: #ccc;
}

:root [data-theme="light"] {
  --background-color: #fff;
  --sidebar-color: #f6f6f6;
  --font-color: black;
  /*--border-color: black;*/
  --chat-window-color: #f6f6f6;
  --napa-blue: #001489;
  --napa-blue-shadow: #00148922;
  --table-border: #ccc;
}

.theme-container {
  height: 100vh;
  background-color: var(--background-color);
  color: var(--font-color);
  font-family: sans-serif;
}

p {
  white-space: pre-wrap;
}

/* Override @bolt */
.tw-bg-semantic-action-background-neutral-white {
  background-color: transparent !important;
}

.tw-text-semantic-action-foreground-neutral-white {
  color: var(--background-color) !important;
}
