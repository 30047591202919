footer {
  margin-bottom: 0.5rem;
}
footer a {
  text-decoration: none;
}

footer a:not(:last-child):after {
  content: " | ";
}
