.feedback-btn-gr {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0 5px 10px;
  width: 95%;
  align-self: flex-end;
}

.btn-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  cursor: pointer;
  background-color: inherit;
  margin: 0 5px 0 5px;
  color: var(--font-color);
}

.btn-active {
  color: green;
}

.up-btn-selected {
  color: green;
}

.down-btn-selected {
  color: red;
}

.feedback-form {
  padding: 10px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-input {
  font-size: 1.1rem;
  padding: 10px;
  color: var(--font-color);
  background-color: var(--background-color);
  outline: none;
  width: 90%;
  resize: none;
  border: grey solid 1px;
}

.submit-feedback-btn {
  margin: 0 0 0 10px;
}
