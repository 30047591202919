.gpc-logo-terms {
  width: 45px;
  padding: 5px;
}

.terms-header-bar {
  display: flex;
  flex-direction: row;
  padding: 12px 24px 8px;
  width: 100%;
  height: 60px;
  background: #001489;
}

.subHeader-text {
  height: 15px;
  font-family: "NAPA SANS" sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #858585;
}

.terms-content {
  width: 100%;
  margin-top: 30px;
  display: flex;
}

.terms-buttons-wrapper {
  flex-grow: 1;
}

.terms-body {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  font-size: 0.8em;
  padding: 20px;
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.accept-button {
  padding: 12px 20px;
  width: 395px;
  background-color: #001489;
  font-family: "NAPA SANS" sans-serif;
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.32px;
  color: #ffffff;
  text-transform: none;
  margin: 10px;
}

.decline-button {
  padding: 12px 20px;
  width: 395px;
  background-color: #ffffff;
  font-family: "NAPA SANS" sans-serif;
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.32px;
  color: #001489;
  text-transform: none;
  margin: 10px;
  border-color: #001489;
}

.red-text {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  padding: 40px 10px 10px 10px;
  color: red;
}
