:root {
  --sidebar-transition: margin 0.3s cubic-bezier(0.36, -0.01, 0, 0.77);
}

.chat-screen-container {
  background: var(--background-color);
  height: 100vh;
  display: flex;
  overflow: hidden;
}

/* SIDEBAR STYLING */
.sidebar {
  background-color: var(--sidebar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  flex: 330px;
  max-width: 330px;
  height: 100vh;
  margin-left: -330px;
  transition: var(--sidebar-transition);
}

@media (min-width: 1024px) {
  .sidebar {
    margin-left: 0;
  }
}

.sidebar-category {
  margin: 2rem 1rem 1rem 1rem;
  display: flex;
  justify-content: start;
  align-items: start;
}

.borderless-btn {
  padding: 1rem;
  border-radius: 3rem;
  color: var(--napa-blue);
  border: none;
  background-color: transparent;
  font-size: 1em;
}

.borderless-btn:hover {
  color: var(--napa-blue);
  border: none;
  background-color: lightgrey;
  font-size: 1em;
}

/* Main part of the Page with chat */
.main {
  flex: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-bottom: 20px;
  transition: var(--sidebar-transition);
}

.input-box-container,
.chat-footer-disclosure {
  width: calc(100% - 4em) !important;
}

@media (min-width: 1024px) {
  .main {
    /* 330px - sidebar size */
    flex: calc(100vw - 330px);
  }
  .chats,
  .input-box-container,
  .chat-footer-disclosure {
    width: calc(80% - 4em) !important;
  }
}

@media (min-width: 1024px) {
  .toggle-sidebar-btn {
    display: none;
  }
}

@media (min-width: 1024px) {
  .mobile-sidebar-toggle-button {
    display: none !important;
  }
}

/* HEADER */
.chat-header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  grid-template-areas: "left-chat-header-button disclosure-notice right-chat-header-button";
}

@media (min-width: 1024px) {
  .chat-header {
    grid-template-columns: 1fr calc(80% - 2em) 1fr;
    gap: 2rem;
  }
}
.mobile-sidebar-toggle-button {
  justify-self: start;
  grid-area: left-chat-header-button;
}

.logout-button {
  justify-self: end;
  grid-area: right-chat-header-button;
}

.disclosure {
  grid-area: disclosure-notice;
}

/* CHATS MAIN */
.chats {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 85vh;
}

/* TEXT INPUT */
.input-box-container {
  font-size: 0.6rem;
  background-color: red;
  justify-self: flex-end;
  margin-top: 1rem;
  padding: 0.125rem 1rem;
  background: var(--chat-window-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 1.5rem;
  border: 2px solid white;
}

.input-box-container-error {
  border: 2px solid red;
  color: red;
}

.chat-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 0.5rem;
  align-self: end;
}

.rephrase-button:not(:disabled) span {
  color: #fec72f;
}

.rephrase-button:not(:disabled):hover span {
  color: #e2a619;
}

.rephrase-status {
  animation: pulse 2s infinite;
  font-size: 1rem;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.rephrase-undo span {
  align-items: center;
}

.chat-text-area {
  padding-top: 0.35rem;
  padding-bottom: 0.15rem;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  border: none;
  outline: none;
  color: var(--font-color);
  height: 100%;
}

.send-button,
.abort-button {
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Note: BOLT fix/override */
.abort-button {
  color: var(--semantic-action-color-background-destructive-default) !important;
}

.chat-text-area textarea {
  font-family: monospace;
  font-size: 1rem;
  color: var(--font-color);
  padding: 0.3rem 0.3rem 0.3rem 0;
  outline: none;
  background: transparent;
  width: 100%;
  resize: none;
  border: var(--chat-window-color);
}

/* CODE + MARKDOWN RESPONSE STYLING */
pre,
code {
  overflow: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-wrap: wrap !important;
}

/* Skeleton MUI override */
.MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.04),
    #aaa
  ) !important;
}

.MuiSkeleton-wave {
  background: #ccc9 !important;
}

.theme-switch-container {
  margin: 1rem;
}

@media (max-width: 1023px) {
  .sidebar-expanded .sidebar {
    margin-left: 0;
  }

  .sidebar-expanded .main {
    margin-right: -330px;
  }
}
