.disclosure {
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: #fff3cd;
  padding: 0.6rem 1rem;
  min-height: 4rem;
  border-radius: 1.3rem;
  font-size: 0.8rem;
  border: 1px solid #ffda6a;
}

.disclosure .warning-icon {
  color: #664d03;
  width: 22px;
  flex-shrink: 0;
}

/* Apply truncation and transition for smooth animation */
.read-more-wrap > span {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Adjust the number of lines to show before truncation */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Show full content when checkbox is checked */
#disclaimer-read-more-toggle:checked ~ .read-more-wrap > span {
  -webkit-line-clamp: unset;
}

.read-less-text,
#disclaimer-read-more-toggle:checked ~ .read-more-wrap .read-more-text {
  display: none;
}

#disclaimer-read-more-toggle:checked ~ .read-more-wrap .read-less-text {
  display: inline;
}

#disclaimer-read-more-toggle:checked ~ .read-more-wrap .read-more-text {
  display: none;
}

.read-more-text,
.read-less-text {
  cursor: pointer;
}

.read-more-trigger {
  font-weight: bold;
}

/* Hide "Read More" on larger screens */
@media (min-width: 1024px) {
  .read-more-trigger {
    display: none;
  }

  /* Ensure full text is visible on larger screens */
  .read-more-wrap > span {
    -webkit-line-clamp: unset;
  }
}
