.gpc-logo {
  padding: 1rem 0 0 0.2rem;
  display: flex;
  padding-left: 15px;
  justify-content: flex-start;
  align-items: center;
}

.gpc-logo .logo {
  color: var(--napa-blue);
  object-fit: cover;
  width: 60px;
  margin-right: 1.5rem;
}

.gpc-logo h2 {
  color: var(--napa-blue);
  font-size: 32px;
  line-height: 40px;
}
