.chat-error-message {
  background-color: #f8d7da;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid #ea868f;
  margin: 1rem 0;
}

@media (max-width: 1023px) {
  .chat-error-message {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}

.chat-error-message .warning-icon {
  margin-right: 1em;
  margin-left: 0.25rem;
  min-width: 22px;
}
