.chat-history-item {
  position: relative;
  padding: 0.25rem 0.5rem;
  margin: 0.1rem 0;
  font-size: 1.3rem;
  /*
     Note: overflow ellipsis
     source: https://www.w3schools.com/cssref/css3_pr_text-overflow.php
  */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
}

.chat-history-item:hover {
  cursor: pointer;
  background-color: rgba(190, 190, 190, 0.2);
}

.delete-conversation-button {
  position: absolute;
  visibility: hidden;
  float: right;
}

.chat-history-item:hover .delete-conversation-button {
  background-color: #ececec !important;
  color: red !important;
  top: 0;
  right: 0;
  visibility: visible;
}

.chat-history-item:hover .delete-conversation-button:hover {
  background-color: #e0e0e0 !important;
}

.chat-history-item.active {
  background-color: var(--napa-blue-shadow);
  color: var(--napa-blue);
}
