.language-heading {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.language-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  column-gap: 0.5rem;
  padding-left: 1rem;
}

.aside-wrapper {
  display: flex;
  margin-inline: 1.3rem;
}

.language-list button {
  color: var(--napa-blue);
  white-space: nowrap;
}

.language-list button.active {
  text-decoration: underline;
  font-weight: 500;
}
