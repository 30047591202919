.text-container h1 {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}

.text-container h2 {
  margin: 10px 0 10px 0;
  font-size: 22px;
  font-weight: 600;
}

.text-container.faq h2 {
  font-size: 18px;
}

.text-container h3 {
  margin: 10px 0 10px 0;
  font-weight: 600;
}

.text-container p {
  margin: 10px 0 10px 0;
}

.text-container.faq p {
  font-style: italic;
}

.text-container ul {
  list-style: disc;
  padding-left: 26px;
}

.text-container ol {
  padding-left: 26px;
  list-style: decimal;
}

.text-container ol > ol {
  list-style: lower-alpha;
}

.text-container ol > ol > ol {
  list-style: lower-roman;
  font-style: italic;
}

.text-container a {
  text-decoration: underline;
  color: revert;
}
