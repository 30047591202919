.landing-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .landing-container {
    flex-direction: row;
  }
}

.leftPane {
  display: flex;
  height: 96px;
  background-color: white;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .leftPane {
    width: 50vw;
    height: 100vh;
  }
}

@media (min-width: 1400px) {
  .leftPane {
    width: 60vw;
  }
}

.leftPane img {
  height: auto;
  width: auto;
  max-width: 75%;
  max-height: 75%;
}

@media (min-width: 1024px) {
  .leftPane img {
    max-width: 50%;
    max-height: 50%;
  }
}

.rightPane {
  height: calc(100vh - 96px);
  width: 100vw;

  background-color: #001489;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (min-width: 1024px) {
  .rightPane {
    height: 100vh;
    width: 50vw;
  }
}

@media (min-width: 1400px) {
  .rightPane {
    width: 40vw;
  }
}

.right-pane-content {
  padding: 0 7%;
  height: 95vh;
  padding-top: 20vh;
}

@media (min-width: 1024px) {
  .right-pane-content {
    padding-top: 30vh;
  }
}

.rightPane h1 {
  color: #ffba00;
  font-size: 3em;
}

.rightPane h2 {
  height: 10rem;
  color: white;
  font-size: 2em;
  font-weight: 400;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (min-width: 1024px) {
  .rightPane h1 {
    font-size: 4em;
  }
  .rightPane h2 {
    font-size: 2em;
  }
}

.login-button {
  height: 50px;
  width: 50%;
  min-width: 100px;
  background-color: #ffbf00;
  outline: yellow;
  color: white;
  text-decoration: solid;
  font-size: 1em;
  border-radius: 50em;
}

.login-button:hover {
  background-color: #f59e00;
  color: white;
}

.login-button:focus {
  background-color: #f59e00;
  color: white;
  outline: yellow;
}

.login-button,
.login-button:focus .login-button:active {
  border: 0;
}

.landing-container footer a {
  color: white;
}
